import React, { useEffect } from "react";
import Header from "./components/Header";
import Carousel from "./components/Carousel";
import AboutUs from "./components/AboutUs";
import Product from "./components/Product";
import { Footer } from "./components/Footer";
import FAQ from "./components/FAQ";
import Arrow from "./design/Arrow";
import ContactUs from "./components/ContactUs";
import Review from "./components/Review";
import AOS from "aos";
import "aos/dist/aos.css";
import InfoSection from "./components/InfoSection";
import SplashScreen from "./components/SplashScreen";
import FloatingIcon from "./design/FloatingIcon";
import Calculator from "./components/Calculator";
import Portfolio from "./components/Portfolio";
import MapIndonesia from "./components/MapIndonesia";
import Benefit from "./components/Benefit";
import { Analytics } from '@vercel/analytics/react';
import { BackgroundHeader } from "./components/BackgroundHeader";
import AboutUsV2 from "./components/AboutUsV2";
import WhyChooseUs from "./components/WhyChooseUs";
import ShortContact from "./components/ShortContact";

const LandingPage: React.FC = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return (
        <div>
            <SplashScreen/>
            <div id="Home"></div>
            <BackgroundHeader/>
            <div id="AboutUs"></div>
            <AboutUsV2/>
            <div id="WhyChooseUs"></div>
            <WhyChooseUs/>
            <div id="Product"></div>
            <Product/>
            <InfoSection/>
            <div id="Calculator"></div>
            <Calculator/>
            <div id="MapIndonesia"></div>
            <MapIndonesia/>
            <div id="Review"></div>
            <Review/>
            <ShortContact/>
            <div id="ContactUs"></div>
            <ContactUs/>
            <div id="Portfolio"></div>
            <Portfolio/>
            <Benefit/>
            <div id="FAQ"></div>
            <FAQ/>
            <Footer/>
            <FloatingIcon/>
            <Header />
            <Analytics />
        </div>
    )
}

export default LandingPage