import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Card, List, ListItem, Avatar, Button } from "@material-tailwind/react";
import '../../Styles.css';
import { Menu, X } from "lucide-react";

const Header: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [isHeader, setIsHeader] = useState(false);

  const createScrollObserver = (targetId: string, callback: (isIntersecting: boolean) => void) => {
    const target = document.getElementById(targetId);
    if (!target) {
      console.warn(`Element with ID ${targetId} not found.`);
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        callback(entry.isIntersecting);
      });
    });

    observer.observe(target);
    return observer;
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const handleScroll = () => {
      setScrollPos(window.scrollY);
      setScrolling(true);
      clearTimeout(timeoutId);

      const observer = createScrollObserver('header-desktop', setIsHeader);

      timeoutId = setTimeout(() => {
        if (observer) observer.disconnect();
      }, 450);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  const handleWhatsAppClick = () => {
    const message = encodeURIComponent(
      "Hallo, saya tertarik menggunakan Bata Ringan. Mohon info lengkapnya ya!"
    );
    const whatsappURL = `https://api.whatsapp.com/send?phone=6282183056369&text=${message}`;
    window.open(whatsappURL, "_blank", "noopener,noreferrer");
  };

  const navLinks = [
    { href: "#Home", text: "Beranda" },
    { href: "#AboutUs", text: "Tentang Kami" },
    { href: "#Product", text: "Produk" },
    { href: "#Calculator", text: "Kalkulator" },
    { href: "#Review", text: "Ulasan" },
  ];

  return (
    <header
      className={`${
        isHeader ? 'text-white' : 'text-black bg-white shadow-sm'
      } text-lg fixed top-0 w-full z-50 transition-all duration-300`}
    >
      {/* Desktop Navigation */}
      <div className="hidden md:block w-full p-3">
        <div className="container mx-auto flex justify-between items-center">
          <img
            src="./images/logo.png"
            alt="logo"
            className="w-56 mr-4"
          />
          <div className="flex items-center">
            {navLinks.map((link) => (
              <a
                key={link.href}
                className="relative font-medium cursor-pointer mx-4 hover:text-gray-300 transition-colors duration-200 after:content-[''] after:absolute after:w-full after:h-0.5 after:bg-current after:left-0 after:-bottom-1 after:rounded-full after:origin-left after:scale-x-0 hover:after:scale-x-100 after:transition-transform after:duration-300"
                href={link.href}
              >
                {link.text}
              </a>
            ))}
          </div>
          <div className="flex items-center">
            <Button 
              onClick={handleWhatsAppClick}
              className="bg-red-500 hover:bg-red-600 text-white"
            >
              Pesan Sekarang
            </Button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      <div className="md:hidden">
        {/* Mobile Header */}
        <div className={`flex items-center p-4 ${
          scrolling ? '' : ''
        } transition-all duration-300`}>
          <div className="flex justify-center items-center space-x-2 w-full">
            <img
              src="./images/logo.png"
              alt="logo"
              className="w-64"
            />
          </div>
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="rounded-lg flex justify-center hover:bg-gray-100 transition-colors duration-200 w-1/6"
          >
            {isMobileMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </div>

        {/* Mobile Menu Overlay */}
        <div
          className={`fixed inset-0 bg-black/50 transition-opacity duration-300 ${
            isMobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
          onClick={() => setIsMobileMenuOpen(false)}
        />

        {/* Mobile Menu Panel */}
        <div
          className={`fixed right-0 top-0 h-full w-64 bg-white transform transition-transform duration-300 ease-in-out shadow-lg ${
            isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className="flex justify-end p-4">
            <button
              onClick={() => setIsMobileMenuOpen(false)}
              className="p-2 rounded-lg hover:bg-gray-100 transition-colors duration-200"
            >
              <X className="h-6 w-6" />
            </button>
          </div>
          
          <nav className="px-4 py-2">
            {navLinks.map((link) => (
              <a
                key={link.href}
                href={link.href}
                className="block py-3 px-4 text-lg font-medium text-gray-800 hover:bg-gray-50 rounded-lg transition-colors duration-200"
                onClick={() => setIsMobileMenuOpen(false)}
              >
                {link.text}
              </a>
            ))}
            
            <div className="mt-6 px-4">
              <Button 
                onClick={() => {
                  handleWhatsAppClick();
                  setIsMobileMenuOpen(false);
                }}
                className="w-full bg-red-500 hover:bg-red-600 text-white py-3"
              >
                Pesan Sekarang
              </Button>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;