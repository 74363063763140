import { Button } from "@material-tailwind/react";

const ShortContact: React.FC = () => {
  const handleWhatsAppClick = () => {
    const message = encodeURIComponent(
      "Hallo, saya tertarik menggunakan Bata Ringan. Mohon info lengkapnya ya!"
    );
    const whatsappURL = `https://api.whatsapp.com/send?phone=6282183056369&text=${message}`;
    window.open(whatsappURL, "_blank", "noopener,noreferrer");
  };

  return (
    <section className="py-12 px-4 md:py-24">
      <div className="container mx-auto max-w-7xl">
        <div className="flex flex-col md:flex-row items-center gap-8 md:gap-12">
          {/* Text Content */}
          <div className="flex flex-col justify-center gap-4 md:gap-6 md:w-1/2 text-center md:text-left">
            <h2 className="font-bold text-xl md:text-2xl lg:text-3xl leading-tight">
              SEGERA HUBUNGI WHATSAPP KAMI UNTUK MENDAPATKAN HARGA BATA RINGAN
              TERBAIK
            </h2>

            <p className="text-sm md:text-base text-gray-600">
              Kami akan memberikan harga bata hebel atau bata ringan per kubik
              sesuai kebutuhan Anda. Klik tombol WhatsApp sekarang!
            </p>

            <div className="mt-2 md:mt-4">
              <Button
                color="red"
                className="w-full md:w-auto px-8 py-3"
                onClick={handleWhatsAppClick}
              >
                Pesan Sekarang
              </Button>
            </div>
          </div>

          {/* Image */}
          <div className="md:w-1/2 mt-8 md:mt-0">
            <img
              className="w-full h-auto rounded-lg shadow-lg"
              src="./images/shortcontact.png"
              alt="Contact illustration"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShortContact;
