import React from 'react';

const AboutUsV2: React.FC = () => {
  return (
    <div className="relative container mx-auto px-4 py-16 md:py-24">
      {/* Background Pattern */}
      <div 
        className="absolute inset-0 opacity-10 pointer-events-none"
        style={{
          backgroundImage: `
            radial-gradient(circle at 0% 0%, #3b82f6 0%, transparent 50%),
            radial-gradient(circle at 100% 100%, #ef4444 0%, transparent 50%)
          `,
          filter: 'blur(100px)'
        }}
      />

      {/* Content Container */}
      <div className="relative flex flex-col md:flex-row md:justify-around md:items-center gap-8 md:gap-12">
        {/* Image Container */}
        <div className="w-full md:w-1/2 flex justify-center">
          <img 
            className="w-full max-w-md rounded-2xl shadow-xl object-cover" 
            src="./images/aboutusv2-new.png" 
            alt="hebel" 
          />
        </div>

        {/* Text Content */}
        <div className="w-full md:w-1/2 flex flex-col justify-center md:max-w-xl">
          <h2 className="font-bold text-2xl md:text-3xl text-center mb-6 md:mb-10">
            TENTANG KAMI
          </h2>
          
          <p className="text-base md:text-lg text-justify leading-relaxed text-gray-700">
            PT Karya Adyakta Perkasa, distributor bata ringan atau hebel hingga 
            mortar di Palembang yang melayani pengiriman ke seluruh Indonesia. 
            Dengan teknologi Jerman, bata ringan kami kuat, ringan, presisi, 
            tahan api, kedap air, dan dapat menghalau udara panas. Kami menjamin 
            pengiriman cepat untuk mendukung proyek konstruksi Anda.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsV2;