import React from 'react';

interface WhyChooseUsItem {
  imgSrc: string;
  altText: string;
  title: string;
  description: string;
}

const whyChooseUsData: WhyChooseUsItem[] = [
  {
    imgSrc: './images/w-1.png',
    altText: 'high-quality',
    title: 'Kualitas Tinggi',
    description: 'Bata ringan atau bata hebel kami dibuat dengan teknologi modern dari Jerman, memastikan bata ringan yang kuat, presisi, tahan api, dan kedap air',
  },
  {
    imgSrc: './images/w-2.png',
    altText: 'experience-and-reputation',
    title: 'Pengalaman dan Reputasi',
    description: 'Sebagai distributor terkemuka berbasis di Palembang, kami memiliki reputasi solid dalam menyediakan bata ringan berkualitas tinggi',
  },
  {
    imgSrc: './images/w-3.png',
    altText: 'fast-delivery',
    title: 'Layanan Pengiriman Cepat',
    description: 'Kami melayani pengiriman ke seluruh Indonesia dengan cepat dan efisien, memastikan proyek Anda berjalan tanpa hambatan',
  },
  {
    imgSrc: './images/w-4.png',
    altText: 'professional-service',
    title: 'Layanan Profesional',
    description: 'Tim kami siap membantu dengan layanan yang ramah dan cepat tanggap untuk memenuhi kebutuhan Anda secara maksimal',
  },
];

const WhyChooseUs: React.FC = () => {
  return (
    <section className="py-16 px-4 md:py-24">
      <div className="container mx-auto max-w-7xl">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-8 md:mb-12">
          MENGAPA MEMILIH KAMI
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8">
          {whyChooseUsData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-4 md:p-6 rounded-lg border border-gray-200 hover:shadow-lg transition-shadow duration-300 bg-white"
            >
              <div className="w-24 h-24 md:w-32 md:h-32 mb-4 md:mb-6 flex items-center justify-center">
                <img
                  className="w-full h-full object-contain"
                  src={item.imgSrc}
                  alt={item.altText}
                  loading="lazy"
                />
              </div>
              
              <h3 className="text-lg md:text-xl font-semibold text-center mb-3">
                {item.title}
              </h3>
              
              <p className="text-sm md:text-base text-gray-600 text-center">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;