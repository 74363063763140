import React from 'react';
import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import LandingPage from './LandingPage/LandingPage';
import ThankyouPage from './ThankyouPage/ThankyouPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/thankyou" element={<ThankyouPage />} />
      </Routes>
    </Router>
  );
}

export default App;
