import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { TableSpecification } from "../design/TableSpecification";

interface CardItem {
  imgSrc: string;
  altText: string;
  title: string;
  description: string;
  delay: string;
}

const cardData: CardItem[] = [
  {
    imgSrc: './images/productv2-2.png',
    altText: 'tentang-kapbataringanpalembang',
    title: 'PEREKAT BATA RINGAN',
    description: '',
    delay: '100',
  },
  {
    imgSrc: './images/productv2-3.png',
    altText: 'tentang-kapbataringanpalembang',
    title: 'PLESTER BATA RINGAN',
    description: '',
    delay: '300',
  },
  {
    imgSrc: './images/productv2-4.png',
    altText: 'tentang-kapbataringanpalembang',
    title: 'ACIAN BATA RINGAN',
    description: '',
    delay: '600',
  },
];

interface DialogData {
  title: string;
  content: React.ReactNode;
  image?: string;
}

const dialogData: DialogData[] = [
  {
    title: 'PEREKAT BATA RINGAN',
    content: (
      <>
        <p className="text-justify mt-4">
          Perekat bata ringan atau mortar dari KAP Bata Ringan memiliki kualitas tinggi untuk hasil bangunan yang kuat dan tahan lama. Mudah digunakan, cepat kering, dan memberikan daya rekat optimal pada bata ringan. Cocok untuk proyek konstruksi dengan hasil rapi dan efisien.
        </p>
        <p className="font-semibold text-xl mt-4">Keunggulan Perekat Bata Ringan</p>
        <ul className="list-disc ml-5 mt-2">
          <li><p className="font-semibold">Daya Rekat Kuat</p></li>
          <p>Perekat Bata Ringan atau mortar dari KAP Bata Ringan menghasilkan sambungan bata ringan atau hebel yang kokoh dan tahan lama</p><br />
          <li><p className="font-semibold">Pemasangan Cepat</p></li>
          <p>Perekat Bata Ringan atau mortar dari KAP Bata Ringan memberikan waktu kering yang singkat, mempercepat proses konstruksi dengan menggunakan bata ringan atau hebel.</p><br />
          <li><p className="font-semibold">Hasil Rapi</p></li>
          <p>Perekat Bata Ringan atau mortar dari KAP Bata Ringan dapat merekat tipis dan merata, mengurangi ketebalan lapisan sambungan bata ringan atau hebel</p><br />
          <li><p className="font-semibold">Efisiensi Biaya</p></li>
          <p>Perekat Bata Ringan atau mortar dari KAP Bata Ringan dapat menghemat penggunaan material bata ringan atau hebel dibandingkan mortar konvensional</p><br />
          <li><p className="font-semibold">Tahan Air</p></li>
          <p>Perekat Bata Ringan atau mortar dari KAP Bata Ringan dapat mencegah penyerapan air berlebih, menjaga kekuatan dinding dengan bata ringan atau hebel</p><br />
          <li><p className="font-semibold">Mudah Diaplikasikan</p></li>
          <p>Perekat Bata Ringan atau mortar dari KAP Bata Ringan lebih praktis, cocok untuk proyek skala kecil maupun besar</p><br />
          <li><p className="font-semibold">Ramah Lingkungan</p></li>
          <p>Perekat Bata Ringan atau mortar dari KAP Bata Ringan dapat mengurangi limbah dan lebih efisien dibandingkan bahan perekat tradisional</p><br />
        </ul>
      </>
    )
  },
  {
    title: 'PLESTER BATA RINGAN',
    content: (
      <>
        <p className="text-justify mt-4">
          Plester Bata Ringan atau mortar dari KAP Bata Ringan adalah solusi tepat untuk finishing dinding bata ringan, memberikan hasil akhir yang halus dan kuat. Dirancang khusus untuk menempel sempurna pada bata ringan, plester ini memudahkan pekerjaan konstruksi dan meningkatkan kualitas hasil akhir.
        </p>
        <p className="font-semibold text-xl mt-4">Keunggulan Plester Bata Ringan</p>
        <ul className="list-disc ml-5 mt-2">
          <li><p className="font-semibold">Daya Lekat Tinggi</p></li>
          <p>Plester Bata Ringan atau mortar KAP memiliki daya lekat tinggi, memastikan plester menempel erat dan kuat pada permukaan bata ringan.</p><br />
          <li><p className="font-semibold">Tahan Retak</p></li>
          <p>Plester Bata Ringan atau mortar dari KAP Bata Ringan dirancang dengan formula khusus yang memiliki sifat tahan retak, memastikan dinding tetap kokoh dan mulus dalam jangka panjang.</p><br />
          <li><p className="font-semibold">Hasil Halus</p></li>
          <p>Plester Bata Ringan atau mortar dari KAP Bata Ringan memberikan hasil akhir yang halus dan rapi, menciptakan permukaan dinding yang siap untuk di cat atau finishing lainnya.</p><br />
          <li><p className="font-semibold">Efisiensi Bahan</p></li>
          <p>Plester Bata Ringan atau mortar dari KAP Bata Ringan dirancang untuk memberikan lapisan tipis yang tetap kuat, memungkinkan penggunaan lebih sedikit bahan tanpa mengorbankan kualitas.</p><br />
          <li><p className="font-semibold">Mudah Diaplikasikan</p></li>
          <p>Plester Bata Ringan atau mortar dari KAP Bata Ringan memiliki formula yang ringan dan mudah dioleskan, mempermudah proses aplikasi pada permukaan bata ringan.</p><br />
        </ul>
      </>
    )
  },
  {
    title: 'ACIAN BATA RINGAN',
    content: (
      <>
        <p className="text-justify mt-4">
          Acian Bata Ringan atau mortar dari KAP Bata Ringan adalah finishing terbaik untuk dinding bata ringan, memberikan hasil akhir yang halus dan tahan lama. Dirancang khusus untuk menutup pori-pori bata, acian ini meningkatkan estetika dan kekuatan dinding.
        </p>
        <p className="font-semibold text-xl mt-4">Keunggulan Acian Bata Ringan</p>
        <ul className="list-disc ml-5 mt-2">
          <li><p className="font-semibold">Hasil Halus</p></li>
          <p>Acian Bata Ringan atau mortar dari KAP Bata Ringan memberikan hasil akhir yang halus dan rapi, menciptakan permukaan dinding yang siap untuk di cat atau finishing lainnya.</p><br />
          <li><p className="font-semibold">Mudah Diaplikasikan</p></li>
          <p>Acian Bata Ringan atau mortar dari KAP Bata Ringan memiliki formula yang ringan dan mudah dioleskan, mempermudah proses aplikasi pada permukaan bata ringan.</p><br />
          <li><p className="font-semibold">Tahan Lama</p></li>
          <p>Acian Bata Ringan atau mortar dari KAP Bata Ringan dirancang untuk <b>tahan lama</b>, memastikan hasil akhir yang awet dan tahan terhadap berbagai kondisi.</p><br />
          <li><p className="font-semibold">Tahan Retak</p></li>
          <p>Acian Bata Ringan atau mortar dari KAP Bata Ringan dirancang dengan formula khusus yang memiliki sifat tahan retak, memastikan dinding tetap kokoh dan mulus dalam jangka panjang.</p><br />
          <li><p className="font-semibold">Efisiensi Bahan</p></li>
          <p>Acian Bata Ringan atau mortar dari KAP Bata Ringan dirancang untuk memberikan lapisan tipis yang tetap kuat, memungkinkan penggunaan lebih sedikit bahan tanpa mengorbankan kualitas.</p><br />
        </ul>
      </>
    )
  },
];

const Product: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);

  const [openDialog, setOpenDialog] = useState<number | null>(null);
  const handleOpenDialog = (index: number | null) => {
    setOpenDialog(index);
  };

  return (
    <div className="mt-48 container mx-auto">
      <div className="flex items-center justify-center">
        <h1 className="text-4xl font-bold">PRODUK</h1>
      </div>
      <div className="mt-4 flex-col items-center">
        <div className="">
          <Card className="w-full flex-col lg:flex-row">
            <CardHeader
              shadow={false}
              floated={false}
              className="m-0 w-full lg:w-2/5 shrink-0 lg:rounded-r-none"
            >
              <img
                src="./images/productv2-1.png"
                alt="product hebel"
                className=""
              />
            </CardHeader>
            <CardBody className="w-full">
              <div className="flex justify-between items-center">
                <Typography
                  variant="h6"
                  color="black"
                  className="uppercase"
                  data-aos="fade-in"
                  data-aos-delay="200"
                >
                  Bata Ringan
                </Typography>
                <a className="inline-block">
                  <Button
                    onClick={handleOpen}
                    variant="text"
                    className="flex items-center gap-2"
                    data-aos="fade-in"
                    color="light-blue"
                    data-aos-delay="400"
                  >
                    Detail Spesifikasi
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                      className="h-4 w-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                      />
                    </svg>
                  </Button>
                </a>
              </div>
              <p className="mt-12 text-xl">
                Bata Ringan atau Bata Hebel ini juga dikenal sebagai Autoclaved Aerated Concrete (AAC) yang diproduksi dengan standar tinggi menggunakan pasir silika dan semen berkualitas, serta teknologi aerasi terbaik. Bata ringan ini lebih kuat dari bata konvensional dan setara dengan beton, namun lebih ringan, memudahkan aplikasi dan memastikan ketepatan estimasi pembangunan.
              </p>
              <Button className="mt-12" color="red" onClick={() => {
                const m = encodeURIComponent("Hallo, saya tertarik menggunakan Bata Ringan. Mohon info lengkapnya ya!")
                const WhatsappURL = "https://api.whatsapp.com/send?phone=6282183056369&text=" + m
                window.open(WhatsappURL, "_blank", "noopener,noreferrer")
              }}>Pesan Sekarang</Button>
            </CardBody>
          </Card>
        </div>
      </div>
      <div className="mt-16">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 text-center">
          {cardData.map((card, index) => (
            <Card key={index} className="mb-12 lg:mb-0" data-aos="fade-up" data-aos-delay={card.delay}>
              <CardBody className="flex flex-col">
                <img
                  src={card.imgSrc}
                  alt={card.altText}
                  className="w-full h-full object-cover"
                />
                <Typography variant="h5" color="blue-gray" className="mb-2 font-semibold">
                  {card.title}
                </Typography>
                <Typography variant="small" color="light-blue" className="mb-2 font-semibold">
                  <a className="inline-block cursor-pointer" onClick={() => handleOpenDialog(index)}>
                    DETAIL SPESIFIKASI
                  </a>
                </Typography>
                <Button className="" color="red" onClick={() => {
                  const m = encodeURIComponent("Hallo, saya tertarik menggunakan Bata Ringan. Mohon info lengkapnya ya!")
                  const WhatsappURL = "https://api.whatsapp.com/send?phone=6282183056369&text=" + m
                  window.open(WhatsappURL, "_blank", "noopener,noreferrer")
                }}>Pesan Sekarang</Button>
              </CardBody>
            </Card>
          ))}
        </div>
      </div>

      <Dialog open={open} handler={handleOpen} className="h-[38rem]">
        <DialogHeader>BATA RINGAN</DialogHeader>
        <DialogBody className="max-h-[29rem] mt-2 scroll-smooth overflow-y-auto w-full scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-300">
          <div className="w-full flex justify-center">
            <img src="./images/dialog2.jpg" alt="" className="w-112" />
          </div>
          <p className="text-justify mt-4">
            Bata Ringan atau Bata Hebel ini juga dikenal sebagai Autoclaved Aerated Concrete (AAC) yang diproduksi dengan standar tinggi menggunakan pasir silika dan semen berkualitas, serta teknologi aerasi terbaik.
            <br /> <br />
          </p>
          <p className="font-semibold text-xl">Keunggulan Bata Ringan/Hebel AAC</p> <br />
          <ul className="list-disc ml-5">
            <li> <p className="font-semibold">Lebih Hemat Biaya Konstruksi</p> </li>
            <p>Bata Ringan atau hebel dari KAP Bata Ringan bersifat ringan namun berkekuatan tekan dan beban tinggi, sehingga beban biaya konstruksi dan struktur bangunan menjadi lebih hemat</p><br />

            <li> <p className="font-semibold">Lebih Hemat Waktu</p> </li>
            <p>Bata Ringan atau hebel dari KAP Bata Ringan memiliki dimensi lebih besar dari bata konvensional, serta cara pengerjaannya praktis sehingga proses pengerjaan dinding 3x lebih cepat</p><br />

            <li> <p className="font-semibold">Lebih Hemat Bahan Aplikasi</p> </li>
            <p>Bata Ringan atau hebel dari KAP Bata Ringan hanya membutuhkan sedikit mortar perekat dalam proses aplikasi. Plesteran dinding pada bata ringan tidak setebal plesteran pada dinding bata konvensional</p> <br />

            <li> <p className="font-semibold">Lebih Hemat Biaya Perawatan</p> </li>
            <p>Bata Ringan atau hebel dari KAP Bata Ringan mempunyai daya serap air yang rendah serta tahan rembesan air sehingga dinding tidak mudah lembab serta aman untuk cat dinding maupun wallpaper</p> <br />

            <li> <p className="font-semibold">Lebih Aman</p> </li>
            <p>Bata Ringan atau hebel dari KAP Bata Ringan bersifat kuat dan low thermal conductivity sehingga tahan gempa dan tahan api dibandingkan bata konvensional</p><br />

            <li> <p className="font-semibold">Ruangan Menjadi Lebih Sejuk</p> </li>
            <p>Bata Ringan atau hebel dari KAP Bata Ringan bersifat ‘thermal insulation’ yang mampu menahan panas dan terik matahari, sehingga ruangan lebih sejuk tanpa alat pendingin udara</p><br />

            <li> <p className="font-semibold">Ruangan Menjadi Lebih Kedap Suara</p> </li>
            <p>Bata Ringan atau hebel dari KAP Bata Ringan memiliki tingkat insulasi suara yang baik sehingga mampu meredam suara secara efektif</p><br />
          </ul>
        </DialogBody>
        <DialogFooter>
          <Button variant="gradient" color="green" onClick={handleOpen}>
            <span>Kembali</span>
          </Button>
        </DialogFooter>
      </Dialog>

      <div>
        {dialogData.map((dialog, index) => (
          <div key={index}>
            <Dialog open={openDialog === index} handler={() => handleOpenDialog(null)} className="h-[38rem]">
              <DialogHeader className="">{dialog.title}</DialogHeader>
              <DialogBody className="max-h-[29rem] pt-0 scroll-smooth overflow-y-auto w-full scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-300">
                {dialog.content}
              </DialogBody>
              <DialogFooter>
                <Button variant="gradient" color="green" onClick={() => handleOpenDialog(null)}>
                  <span>Kembali</span>
                </Button>
              </DialogFooter>
            </Dialog>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
