import { Button } from "@material-tailwind/react";
import { useEffect } from "react"
import { useLocation } from "react-router-dom";

export default function ThankyouPage() {
    const location = useLocation();

    // Helper function to get query parameters from the URL
    const getQueryParams = (query: string) => {
        return new URLSearchParams(query);
    };

    return (
        <div className="container flex items-center justify-center min-h-screen">
            <div className="text-center p-8 max-w-lg">
                <h1 className="text-3xl font-bold mb-4">
                    TERIMA KASIH
                </h1>
                <p className="text-lg">
                    Telah mempercayakan kepada KAP Bata Ringan, silahkan teruskan untuk tanya lebih lanjut via WhatsApp, kami akan mengarahkan anda ke whatsapp dalam beberapa waktu
                </p>
                <Button className="mt-5" color="green" onClick={() => {
                    const params = getQueryParams(location.search);
                    const name = params.get('name');
                    const message = params.get('message');
                    const m = encodeURIComponent("Halo saya " + name + ", " + message)
                    const WhatsappURL = "https://api.whatsapp.com/send?phone=6282183056369&text=" + m;
                    window.open(WhatsappURL, "_blank", "noopener,noreferrer")
                }}>Chat ke WhatsApp</Button>
            </div>
        </div>
    )
}