import {
  Card,
  Input,
  Button,
  Typography,
  Textarea,
} from "@material-tailwind/react";
import { useState } from "react";

export function SimpleRegistrationForm() {
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");
  const [messageError, setMessageError] = useState("");
  const [message, setMessage] = useState("");

  const validateName = (value: string) => {
    if (value.length === 0) {
      setNameError("Nama harus diisi");
    } else if (value.length > 20) {
      setNameError("Nama tidak boleh lebih dari 20 karakter");
    } else {
      setNameError("");
    }
  };

  const validateMessage = (value: string) => {
    if (value.length === 0) {
      setMessageError("Pesan harus diisi");
    } else if (value.length > 200) {
      setMessageError("Pesan tidak boleh lebih dari 200 karakter");
    } else {
      setMessageError("");
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
    validateName(value);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setMessage(value);
    validateMessage(value);
  };

  const openNewTab = (e: React.FormEvent) => {
    e.preventDefault();

    validateMessage(message);
    validateName(name);
    if (messageError != "" || message == "" || nameError != "" || name == "") {
      return;
    }

    const m = encodeURIComponent(message);
    const n = encodeURIComponent(name);

    // Redirect to the thank you page with name and message as query parameters
    const thankYouPageURL = `/thankyou?name=${n}&message=${m}`;

    // Open Thank You page in a new tab
    window.open(thankYouPageURL, "_blank");

    // Clear the form
    setName("");
    setMessage("");
  };

  return (
    <Card color="transparent" shadow={false}>
      <form className="mt-8 mb-2" onSubmit={openNewTab}>
        <div className="mb-1 flex flex-col gap-6">
          <Typography variant="h6" color="blue-gray" className="-mb-3 text-xl">
            Nama
          </Typography>
          <Input
            name="from_name"
            placeholder="Budiawan"
            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            onChange={handleNameChange}
            value={name}
            crossOrigin={undefined}
            error={!!nameError}
          />

          {nameError && (
            <Typography color="red" className="text-sm">
              {nameError}
            </Typography>
          )}

          <Typography variant="h6" color="blue-gray" className="-mb-3 text-xl">
            Kebutuhan Anda
          </Typography>
          <Textarea
            name="message"
            placeholder=" Mau pesan bata ringan 100 box"
            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            onChange={handleMessageChange}
            value={message}
            error={!!messageError}
          />

          {messageError && (
            <Typography color="red" className="text-sm">
              {messageError}
            </Typography>
          )}
        </div>

        {/* Display error messages above the button
        {(nameError || messageError) && (
          <Typography color="red" className="text-sm mt-2">
            {nameError || messageError}
          </Typography>
        )} */}

        <Button className="mt-6" color="red" fullWidth type="submit">
          Pesan Sekarang
        </Button>
      </form>
    </Card>
  );
}
