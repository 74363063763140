import React from "react";
import { ListSocmed } from "../design/ListSocmed";
import { SimpleRegistrationForm } from "../design/Form";
import { ListEcommerce } from "../design/ListEcommerce";

const ContactUs: React.FC = () => {
  return (
    <section className="py-16 md:py-24 px-4">
      <div className="container mx-auto max-w-7xl">
        {/* Main Content */}
        <div className="flex flex-col lg:flex-row gap-8 lg:gap-12">
          {/* Contact Information */}
          <div className="w-full lg:w-2/3">
            <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-6 md:p-8">
              <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl mb-4 md:mb-6">
                Hubungi Kami
              </h1>
              
              <p className="text-lg md:text-xl text-gray-600 mb-8">
                Kami distributor bata ringan atau bata hebel hingga dan mortar di Palembang, 
                siap membantu dalam memenuhi kebutuhan Anda!
              </p>

              <div className="space-y-4 text-gray-700">
                <div className="flex items-center space-x-3">
                  <span className="font-medium">HP:</span>
                  <a href="tel:087897208010" className="hover:text-blue-600 transition-colors">
                    0878 9720 8010
                  </a>
                </div>

                <div className="flex items-center space-x-3">
                  <span className="font-medium">WA:</span>
                  <a href="https://wa.me/6282183056369" className="hover:text-blue-600 transition-colors">
                    0821 8305 6369
                  </a>
                </div>

                <div className="flex items-center space-x-3">
                  <span className="font-medium">Email:</span>
                  <a href="mailto:pt.karyaadyaktaperkasa@gmail.com" className="hover:text-blue-600 transition-colors">
                    pt.karyaadyaktaperkasa@gmail.com
                  </a>
                </div>

                <div className="pt-2">
                  <span className="font-medium">Alamat:</span>
                  <p className="mt-2">
                    Jl. Bypass Alang-Alang Lebar, Alang Alang Lebar, 
                    Kec. Alang-Alang Lebar, Kota Palembang, 
                    Sumatera Selatan 30961
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Registration Form */}
          <div className="w-full lg:w-1/3" data-aos="fade-right" data-aos-delay="1000">
            <SimpleRegistrationForm />
          </div>
        </div>

        {/* Map */}
        <div className="mt-12 md:mt-16" data-aos="fade-in" data-aos-duration="1000" data-aos-delay="500">
          <div className="rounded-lg overflow-hidden shadow-lg">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.6329454847664!2d104.67965997570076!3d-2.9214482396011667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e3b758406706105%3A0x153609cc79b7843c!2sBATA%20RINGAN%20AAC%20PALEMBANG!5e0!3m2!1sen!2sid!4v1704212544618!5m2!1sen!2sid" 
              className="w-full h-64 md:h-96 border-0" 
              loading="lazy"
              title="Location Map"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;