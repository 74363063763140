import React from 'react';
import { Button } from "@material-tailwind/react";

export const BackgroundHeader = () => {
    const handleWhatsAppClick = () => {
        const message = encodeURIComponent(
            "Hallo, saya tertarik menggunakan Bata Ringan. Mohon info lengkapnya ya!"
        );
        const whatsappURL = `https://api.whatsapp.com/send?phone=6282183056369&text=${message}`;
        window.open(whatsappURL, "_blank", "noopener,noreferrer");
    };

    return (
        <div className="relative w-full h-screen">
            {/* Background Image */}
            <img 
                src="./images/background-header.png" 
                alt="Background" 
                className="absolute inset-0 w-full h-full object-cover"
            />
            
            {/* Overlay for better text readability */}
            <div className="absolute inset-0 bg-black/30" />
            
            {/* Header Observer Element */}
            <div 
                id="header-desktop" 
                className="absolute top-0 h-56 w-full" 
            />
            
            {/* Content Container */}
            <div className="relative h-full flex items-center">
                <div className="container mx-auto px-4 md:px-6">
                    <div className="max-w-2xl text-white">
                        {/* Heading */}
                        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 leading-tight">
                            <span className="block">SOLUSI BANGUNAN</span>
                            <span className="block">KOKOH DAN TAHAN LAMA</span>
                        </h1>
                        
                        {/* Description */}
                        <p className="text-base md:text-lg mb-6 leading-relaxed">
                            Segera beralih ke bata ringan, bangunan Anda 
                            <span className="hidden md:inline"><br /></span>{" "}
                            lebih kokoh, tahan lama, dan mudah dipasang
                        </p>
                        
                        {/* CTA Button */}
                        <Button
                            onClick={handleWhatsAppClick}
                            className="bg-red-500 hover:bg-red-600 text-white px-6 py-3 text-base md:text-lg font-medium transition-colors duration-200"
                        >
                            Pesan Sekarang
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BackgroundHeader;